<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Criar" }} Certificado
          </h3>
        </div>
        <div class="card-body">
          <div class="col-md-12">
            <form action="">
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-md-12">nome:*</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.nome"
                    placeholder="Digite o nome..."
                  />
                </div>
                <div class="col-md-12">
                  <label class="col-md-12">Senha certificado:*</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.senha"
                    placeholder="Digite a senha..."
                  />
                </div>

                <div class="col-md-12">
                  <label class="col-md-12">Certificado:*</label>
                  <input
                    class="form-control"
                    type="file"
                    id="file"
                    ref="file"
                    multiple="multiple"
                    v-on:change="handleFileUpload()"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12 text-center">
                  <button
                    :disabled="verif"
                    v-if="lista_campos == ''"
                    @click.prevent="confirm('cria')"
                    class="btn btn-primary"
                  >
                    salvar
                    <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                    ></b-spinner>
                  </button>

                  <button
                    :disabled="verif"
                    v-if="lista_campos != ''"
                    @click="confirm('edita')"
                    class="btn btn-primary"
                  >
                    editar

                    <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                    ></b-spinner>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      file: [],
      form: {
        nome: "",
        senha: null,
      },

      verif: false,
    };
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },

    mensagem_alert() {
      return this.$store.state.certificado.mensagem_alert;
    },
  },

  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` um certificado no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo === "cria") this.create();
            if (tipo === "edita") this.update();
          }
        },
      });
    },
    async create() {
                let formData = new FormData();


      this.verif = true;
      for (var i = 0; i < this.file.length; i++) {
        let fil = this.file[i];
        formData.append("nome", this.form.nome);
        formData.append("senha", this.form.senha);

        formData.append("file[" + i + "]", fil);
      }

      await this.$store.dispatch("certificado/create_certificado", formData);
      this.verif = false;
      //  this.$store.dispatch("guardarDados", this.lista_dados_guardados);
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "arquivex",
      });
    },
    async update() {
           let formData = new FormData();

      this.verif = true;
      for (var i = 0; i < this.file.length; i++) {
        let fil = this.file[i];
        formData.append("id", this.form.id);
        formData.append("nome", this.form.nome);
        formData.append("senha", this.form.senha);

        formData.append("file[" + i + "]", fil);
      }
      await this.$store.dispatch("certificado/update_certificado", formData);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "arquivex",
      });
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          file: this.lista_campos.file,
          nome: this.lista_campos.nome,
        };
    },

    handleFileUpload() {
      var b = this.$refs.file.files.length;
      for (var i = 0; i < b; i++) {
        var a = this.$refs.file.files[i];

        var ultima = a.name.substring(a.name.lastIndexOf(".") + 1);
        var res = 0;
        if (ultima == "jpg") {
          this.file.push(this.$refs.file.files[i]);
          res = 1;
        }
        if (ultima == "jpeg") {
          this.file.push(this.$refs.file.files[i]);
          res = 1;
        }
        if (ultima == "png") {
          this.file.push(this.$refs.file.files[i]);
          res = 1;
        }
        if (ultima == "pdf") {
          this.file.push(this.$refs.file.files[i]);
          res = 1;
        }
        if (ultima == "docx") {
          this.file.push(this.$refs.file.files[i]);
          res = 1;
        }
        if (ultima == "pptx") {
          this.file.push(this.$refs.file.files[i]);
          res = 1;
        }
        if (ultima == "pfx") {
          this.file.push(this.$refs.file.files[i]);
          res = 1;
        }

        if (res == 0) {
          alert(
            "Este não é um certificado valido, Selecione um certificado de imagem do tipo. jpg, jpeg, png, pdf, docx, pptx"
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>